import React, { useEffect, useRef, useState } from "react";
import PromptPage from "./components/PromptPage";
import OutputPage from "./components/OutputPage";
import autoAnimate from "@formkit/auto-animate";
import { SettingsProvider } from "./contexts/settings";
import { PromptsProvider } from "./contexts/prompts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GeneratedThreadProvider } from "./contexts/generated-thread";
import { useMediaQuery } from "@uidotdev/usehooks";

const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    document.documentElement.classList.add("dark");
  }, []);
  const [isOutputPageVisible, setIsOutputPageVisible] = useState(false);
  const isMediumDevice = useMediaQuery("screen and (max-width: 992px)");
  console.log(isMediumDevice);

  const thisRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (thisRef.current) {
      autoAnimate(thisRef.current);
    }
  }, [thisRef]);
  return (
    <QueryClientProvider client={queryClient}>
      <PromptsProvider>
        <SettingsProvider>
          <GeneratedThreadProvider>
            <div className="flex items-center w-full h-full" ref={thisRef}>
              {isMediumDevice && isOutputPageVisible ? null : (
                <PromptPage setIsOutputPageVisible={setIsOutputPageVisible} />
              )}
              {isOutputPageVisible && (
                <OutputPage
                  setIsOutputPageVisible={setIsOutputPageVisible}
                  showLeftBorder={!isMediumDevice}
                />
              )}
            </div>
          </GeneratedThreadProvider>
        </SettingsProvider>
      </PromptsProvider>
    </QueryClientProvider>
  );
};

export default App;
