import React, { useEffect, useState } from "react";

const ButtonGroup = ({
  buttons,
  activeButtonIndex = 0,
  onChange,
}: {
  buttons: Array<string>;
  activeButtonIndex?: number;
  onChange?: (index: number) => void;
}) => {
  const [activeIndex, setActiveIndex] = useState(activeButtonIndex);
  const gap = 2;
  const paddingX = 2;
  const getButtonWidth = () => {
    const staticCutoff = gap * (buttons.length - 1) - paddingX;
    return `calc(${100 / buttons.length}% - ${staticCutoff}px)`;
  };

  useEffect(() => {
    if (onChange) {
      onChange(activeIndex);
    }
  }, [activeIndex]);

  return (
    <div className="w-full flex items-center border border-border rounded-lg p-0.5 gap-0.5 relative">
      <div
        className="absolute top-0.5 left-0.5 h-[calc(100%_-_4px)] bg-primary rounded-md z-0 transition-all duration-300"
        style={{
          width: getButtonWidth(),
          transform: `translateX(${activeIndex * 100}%)`,
        }}
      ></div>
      {buttons.map((button, index) => {
        return (
          <button
            className={`flex-1 text-sm font-medium scale-100 h-9 rounded-md transition-all duration-300 ${
              index === activeIndex
                ? "text-primary-foreground"
                : "text-foreground hover:bg-foreground/10"
            }`}
            key={index}
            onClick={() => {
              setActiveIndex(index);
            }}
          >
            {button}
          </button>
        );
      })}
    </div>
  );
};

export default ButtonGroup;
