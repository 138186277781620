import React from "react";

const PostSkeleton = ({
  showConnector = true,
  appearAfter = 0,
}: {
  showConnector?: boolean;
  appearAfter?: number;
}) => {
  return (
    <div
      className="p-4 bg-foreground/5 rounded-xl border border-border flex items-start gap-2 relative opacity-0 animate-appear"
      data-component="post"
      style={{
        animationDelay: `${appearAfter}ms`,
      }}
    >
      {showConnector && (
        <div className="w-[2px] h-full left-[36px] translate-y-[36px] rounded-[1px] bg-foreground/[0.02] absolute"></div>
      )}
      <div>
        <div className="h-10 w-10 rounded-full border border-border relative z-10 overflow-hidden bg-foreground/10 animate-pulse"></div>
      </div>
      <div className="flex flex-col gap-4 flex-1">
        <div className="flex items-center gap-2">
          <strong className="bg-foreground/5 w-16 h-5 rounded-md animate-pulse delay-700"></strong>
          <span className="bg-foreground/5 w-24 h-5 rounded-md animate-pulse delay-300"></span>
        </div>
        <div className="flex flex-col gap-1.5 flex-1">
          <div
            className="bg-foreground/10 w-[90%] h-6 rounded-md animate-pulse duration-1000"
            style={{
              animationDelay: "200ms",
              animationDuration: "2000ms",
            }}
          ></div>
          <div
            className="bg-foreground/10 w-[70%] h-6 rounded-md animate-pulse duration-1000"
            style={{
              animationDelay: "800ms",
              animationDuration: "2000ms",
            }}
          ></div>
          <div
            className="bg-foreground/10 w-[80%] h-6 rounded-md animate-pulse duration-1000"
            style={{
              animationDelay: "600ms",
              animationDuration: "2000ms",
            }}
          ></div>
          <div
            className="bg-foreground/10 w-[45%] h-6 rounded-md animate-pulse duration-1000"
            style={{
              animationDelay: "400ms",
              animationDuration: "2000ms",
            }}
          ></div>
        </div>
        <div className="flex items-center justify-between mt-2">
          <div className="h-6 w-6 rounded-md bg-foreground/5"></div>
          <div className="h-6 w-6 rounded-md bg-foreground/5"></div>
          <div className="h-6 w-6 rounded-md bg-foreground/5"></div>
          <div className="h-6 w-6 rounded-md bg-foreground/5"></div>
          <span className="flex items-center gap-2">
            <div className="h-6 w-6 rounded-md bg-foreground/5"></div>
            <div className="h-6 w-6 rounded-md bg-foreground/5"></div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PostSkeleton;
