import { Post as PostType } from "@/contexts/generated-thread";
import { parseTweetString } from "@/lib/utils";
import {
  BarChart2,
  Bookmark,
  Heart,
  MessageCircle,
  Repeat,
  Share,
  Upload,
} from "lucide-react";
import React, { forwardRef } from "react";

const Post = forwardRef<
  HTMLDivElement,
  PostType & {
    showConnector?: boolean;
  }
>(({ body, showConnector = true }, ref) => {
  const partsOfBody = parseTweetString(body);

  return (
    <div
      className="p-4 rounded-xl border border-border flex items-start gap-2 relative"
      data-component="post"
      ref={ref}
    >
      {showConnector && (
        <div className="w-[2px] h-full left-[36px] translate-y-[36px] rounded-[1px] bg-muted absolute"></div>
      )}
      <div>
        <div className="h-10 w-10 rounded-full border border-border relative z-10 overflow-hidden">
          <img
            src="/default_profile_normal.png"
            className="absolute top-0 left-0 h-full w-full object-cover"
          />
        </div>
      </div>
      <div className="flex flex-col gap-0.5 flex-1">
        <div className="flex items-center gap-2">
          <strong>UserName</strong>
          <span className="text-muted-foreground">@username · Now</span>
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {partsOfBody.map((part, index) => {
            if (part.type === "text") {
              return <span key={index}>{part.value}</span>;
            } else if (part.type === "tag") {
              return (
                <a
                  key={index}
                  className={"text-blue-500 dark:text-blue-400 hover:underline"}
                  href={`https://x.com/hashtag/${part.value.slice(1)}`}
                  target="_blank"
                >
                  {part.value}
                </a>
              );
            }
          })}
        </div>
        <div className="flex items-center justify-between mt-2">
          <MessageCircle size={16} className="text-muted-foreground" />
          <Repeat size={16} className="text-muted-foreground" />
          <Heart size={16} className="text-muted-foreground" />
          <BarChart2 size={16} className="text-muted-foreground" />
          <span className="flex items-center gap-2">
            <Bookmark size={16} className="text-muted-foreground" />
            <Upload size={16} className="text-muted-foreground" />
          </span>
        </div>
      </div>
    </div>
  );
});

Post.displayName = "Post";

export default Post;
