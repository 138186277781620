import React, { useEffect, useRef } from "react";
import { Button } from "../ui/button";
import { ArrowRight, LoaderCircle, Plus, Settings2 } from "lucide-react";
import { TextArea } from "react-auto-resize-textarea";
import autoAnimate from "@formkit/auto-animate";
import { usePrompts } from "@/contexts/prompts";
import useGeneratedThread from "@/contexts/generated-thread";
import ModernTooltip, { TooltipDirections } from "../ui/modern-tooltip";
import { DEFAULT_SETTINGS, useSettings } from "@/contexts/settings";

const PromptBox = ({
  setIsOutputPageVisible,
  viewSettingsPage,
}: {
  setIsOutputPageVisible: (visible: boolean) => void;
  viewSettingsPage?: () => void;
}) => {
  const {
    postContent,
    setPostContent,
    isUserPromptApplicable: isInstructionPromptVisible,
    setUserPromptApplicable: setIsInstructionPromptVisible,
    userPrompt: instructions,
    setUserPrompt: setInstructions,
  } = usePrompts();
  const { settings, setSettings } = useSettings();
  const utilsWrapperRef = useRef<HTMLDivElement | null>(null);
  const userPromptRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (utilsWrapperRef.current) {
      autoAnimate(utilsWrapperRef.current);
    }
  }, [utilsWrapperRef]);

  useEffect(() => {
    const userPrompt = userPromptRef.current;
    if (!userPrompt) return;
    if (isInstructionPromptVisible) {
      userPrompt.focus();
    }
  }, [isInstructionPromptVisible]);

  const { isLoading, error, data, fetch } = useGeneratedThread();

  const submitRequest = () => {
    fetch();
    setIsOutputPageVisible(true);
  };

  return (
    <>
      <TextArea
        className="bg-transparent p-4 focus:border-none focus:outline-none w-full resize-none max-h-[40vh] min-h-20 disabled:opacity-50"
        placeholder="Enter your post content here..."
        value={postContent}
        onChange={(e) => {
          setPostContent(e.target.value);
        }}
        autoResize
        disabled={isLoading}
      />
      <div className="flex flex-col w-full p-2" ref={utilsWrapperRef}>
        {isInstructionPromptVisible && (
          <div className=" w-full">
            <TextArea
              className="w-full bg-background border border-border rounded-lg min-h-[40px] max-h-[160px] py-[6px] px-4 resize-none disabled:opacity-50"
              defaultHeight={40}
              placeholder="Add manual instructions here..."
              onChange={(e) => {
                setInstructions(e.target.value);
              }}
              value={instructions}
              autoResize
              disabled={isLoading}
              ref={userPromptRef}
            />
          </div>
        )}
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-1">
            <Button
              variant={"ghost"}
              size={"sm"}
              className="text-muted-foreground hover:text-foreground disabled:opacity-50"
              onClick={viewSettingsPage}
              disabled={isLoading}
            >
              <Settings2 size={16} className="mr-1" />
              <span>
                {DEFAULT_SETTINGS === settings ? "Default" : "Custom"}
              </span>
            </Button>
            <ModernTooltip
              tooltipContent="Add manual instructions."
              tooltipDirection={TooltipDirections.RIGHT}
              tooltipWidth={200}
              openOnFocus
            >
              <Button
                size={"sm"}
                variant={"outline"}
                className="h-8 w-8 p-0"
                onClick={() => setIsInstructionPromptVisible((prev) => !prev)}
                disabled={isLoading}
              >
                <Plus
                  size={16}
                  className={`${
                    isInstructionPromptVisible ? "rotate-[225deg]" : ""
                  } transition-transform duration-200`}
                />
              </Button>
            </ModernTooltip>
          </div>
          <Button
            size={"sm"}
            variant={"default"}
            onClick={submitRequest}
            disabled={
              (postContent === "" &&
                (isInstructionPromptVisible ? instructions : "") === "") ||
              isLoading
            }
          >
            <span>Submit</span>
            {isLoading ? (
              <LoaderCircle size={16} className="ml-1 animate-spin" />
            ) : (
              <ArrowRight size={16} className="ml-1" />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PromptBox;
