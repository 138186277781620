import { createContext, useContext, useState } from "react";

type PromptsContext = {
  postContent: string;
  isUserPromptApplicable: boolean;
  userPrompt: string;
  setPostContent: React.Dispatch<React.SetStateAction<string>>;
  setUserPromptApplicable: React.Dispatch<React.SetStateAction<boolean>>;
  setUserPrompt: React.Dispatch<React.SetStateAction<string>>;
};

const PromptsContext = createContext<PromptsContext | null>(null);

export const PromptsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [postContent, setPostContent] = useState<string>("");
  const [isUserPromptApplicable, setUserPromptApplicable] = useState(false);
  const [userPrompt, setUserPrompt] = useState<string>("");

  return (
    <PromptsContext.Provider
      value={{
        postContent,
        isUserPromptApplicable,
        userPrompt,
        setPostContent,
        setUserPromptApplicable,
        setUserPrompt,
      }}
    >
      {children}
    </PromptsContext.Provider>
  );
};

export const usePrompts = () => {
  const prompts = useContext(PromptsContext);
  if (!prompts) {
    throw new Error("usePrompts must be wrapped in <PromptsProvider>");
  }
  return prompts;
};
