import React, { useEffect, useState } from "react";
import { Button } from "../ui/button";
import {
  CheckCircle2,
  ChevronLeft,
  Circle,
  CircleHelp,
  Save,
} from "lucide-react";
import { Switch } from "../ui/switch";
import ButtonGroup from "../ui/button-group";
import Slider from "../ui/slider";
import { useSettings } from "@/contexts/settings";
import ModernTooltip, { TooltipDirections } from "../ui/modern-tooltip";

const Label = ({
  children,
  tooltipContent,
}: {
  children: React.ReactNode;
  tooltipContent: string;
}) => (
  <label className="font-semibold text-muted-foreground text-sm flex items-center gap-2">
    {children}
    <ModernTooltip
      tooltipContent={tooltipContent}
      tooltipDirection={TooltipDirections.RIGHT}
      tooltipWidth={220}
      className="font-normal"
      openOnFocus
      renderAsButton
    >
      <CircleHelp size={14} />
    </ModernTooltip>
  </label>
);

const Settings = ({ viewPromptPage }: { viewPromptPage: () => void }) => {
  const { settings, setSettings } = useSettings();

  const [characterLimit, setCharacterLimit] = useState<number>(
    settings.characterLimit
  );
  const [limitUsage, setLimitUsage] = useState<number>(
    settings.characterLimitUsage
  );
  const [isLimitUsageAuto, setLimitUsageAuto] = useState(
    settings.isCharacterLimitAuto
  );
  const [postCount, setPostCount] = useState<number>(settings.postCount);
  const [isPostCountAuto, setPostCountAuto] = useState(
    settings.isPostCountAuto
  );
  const [writingStyle, setWritingStyle] = useState<
    "UNCHANGED" | "FUN" | "FORMAL"
  >(settings.writingStyle);
  const [generateTags, setGenerateTags] = useState<
    "NONE" | "FIRST_POST" | "ALL_POSTS"
  >(settings.generateTags);
  const [useEmojis, setUseEmojis] = useState(settings.useEmojis);

  const characterLimitTuples = [
    ["Basic", 250],
    ["Premium", 500],
    ["Premium +", 1000],
  ];
  const writingStyleTuples = [
    ["Unchanged", "UNCHANGED"],
    ["Fun", "FUN"],
    ["Formal", "FORMAL"],
  ];
  const generateTagsTuples = [
    ["None", "NONE"],
    ["First Post", "FIRST_POST"],
    ["All Posts", "ALL_POSTS"],
  ];

  const save = () => {
    setSettings({
      ...settings,
      characterLimit,
      characterLimitUsage: limitUsage,
      isCharacterLimitAuto: isLimitUsageAuto,
      postCount,
      isPostCountAuto,
      writingStyle,
      generateTags,
      useEmojis,
    });
  };

  useEffect(() => {
    setCharacterLimit(settings.characterLimit);
    setLimitUsage(settings.characterLimitUsage);
    setLimitUsageAuto(settings.isCharacterLimitAuto);
    setPostCount(settings.postCount);
    setPostCountAuto(settings.isPostCountAuto);
    setWritingStyle(settings.writingStyle);
    setGenerateTags(settings.generateTags);
    setUseEmojis(settings.useEmojis);
  }, [settings]);

  console.log(process.env);

  return (
    <>
      <div className="flex items-center gap-2">
        <Button
          variant={"ghost"}
          size={"sm"}
          onClick={viewPromptPage}
          className="h-10 w-10 p-0"
        >
          <ChevronLeft size={20} className="mr" />
        </Button>
        <h1 className="text-xl font-bold flex items-center">
          <span>Settings</span>
        </h1>
      </div>
      <div className="flex flex-col gap-4 px-4 mt-2 w-full">
        <div className="w-full flex flex-col gap-1">
          <Label tooltipContent="The maximum number of characters allowed in a post by X rules.">
            Character Limit
          </Label>
          <ButtonGroup
            buttons={characterLimitTuples.map((tuple) => tuple[0] as string)}
            activeButtonIndex={characterLimitTuples.findIndex(
              (tuple) => tuple[1] === characterLimit
            )}
            onChange={(index) =>
              setCharacterLimit(characterLimitTuples[index][1] as number)
            }
          />
        </div>
        <div className="w-full flex flex-col gap-1">
          <Label tooltipContent="Percentage of character limit to be used per post.">
            Limit Usage
          </Label>
          <div className="flex items-center gap-1">
            <Slider
              min={25}
              max={100}
              value={limitUsage}
              onChange={setLimitUsage}
              valueSuffix="%"
              disabled={isLimitUsageAuto}
            />
            <Button
              size={"sm"}
              variant={isLimitUsageAuto ? "default" : "outline"}
              className="h-8 w-24"
              onClick={() => setLimitUsageAuto((prev) => !prev)}
            >
              {isLimitUsageAuto ? (
                <CheckCircle2 size={16} className="mr-1" />
              ) : (
                <Circle size={16} className="mr-1" />
              )}
              Auto
            </Button>
          </div>
        </div>
        <div className="w-full flex flex-col gap-1">
          <Label tooltipContent="The maximum number of posts to generate.">
            Post Count
          </Label>
          <div className="flex items-center gap-1">
            <Slider
              min={1}
              max={20}
              value={postCount}
              onChange={setPostCount}
              disabled={isPostCountAuto}
            />
            <Button
              size={"sm"}
              variant={isPostCountAuto ? "default" : "outline"}
              className="h-8 w-24"
              onClick={() => setPostCountAuto((prev) => !prev)}
            >
              {isPostCountAuto ? (
                <CheckCircle2 size={16} className="mr-1" />
              ) : (
                <Circle size={16} className="mr-1" />
              )}
              Auto
            </Button>
          </div>
        </div>
        <div className="w-full flex flex-col gap-1">
          <Label tooltipContent="The writing style to use for the generated posts.">
            Writing Style
          </Label>
          <ButtonGroup
            buttons={writingStyleTuples.map((tuple) => tuple[0])}
            activeButtonIndex={writingStyleTuples.findIndex(
              (tuple) => tuple[1] === writingStyle
            )}
            onChange={(index) =>
              setWritingStyle(
                writingStyleTuples[index][1] as typeof writingStyle
              )
            }
          />
        </div>
        <div className="w-full flex flex-col gap-1">
          <Label tooltipContent="Whether to generate tags for the generated posts.">
            Generate Tags
          </Label>
          <ButtonGroup
            buttons={generateTagsTuples.map((tuple) => tuple[0])}
            activeButtonIndex={generateTagsTuples.findIndex(
              (tuple) => tuple[1] === generateTags
            )}
            onChange={(index) =>
              setGenerateTags(
                generateTagsTuples[index][1] as typeof generateTags
              )
            }
          />
        </div>
        <div className="w-full flex items-center justify-between">
          <Label tooltipContent="Whether to use emojis in the generated posts.">
            Use Emojis
          </Label>
          <Switch checked={useEmojis} onCheckedChange={setUseEmojis} />
        </div>
      </div>
      <div className="flex items-center justify-end w-full mt-6">
        <Button
          size={"sm"}
          onClick={() => {
            save();
            viewPromptPage();
          }}
        >
          <Save size={16} className="mr-1" />
          <span>Save</span>
        </Button>
      </div>
    </>
  );
};

export default Settings;
