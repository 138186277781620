import React, { useEffect, useRef, useState } from "react";

import autoAnimate from "@formkit/auto-animate";
import Settings from "./Settings";
import PromptBox from "./PromptBox";

const PromptPage = ({
  setIsOutputPageVisible,
}: {
  setIsOutputPageVisible: (visible: boolean) => void;
}) => {
  const [page, setPage] = useState<"PROMPT" | "SETTINGS">("PROMPT");
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      autoAnimate(containerRef.current);
    }
  }, [containerRef]);

  return (
    <div className="flex-1 relative">
      <div className="home-ball absolute h-60 w-60 rounded-full blur-3xl bg-foreground/30 opacity-0 left-[50%] top-[50%]"></div>
      <div
        className="home-ball absolute h-28 w-28 rounded-full blur-3xl bg-foreground/30 opacity-0 left-[50%] top-[50%] -translate-x-40 -translate-y-48"
        style={{
          animationDelay: "4000ms",
        }}
      ></div>
      <div
        className="home-ball absolute h-48 w-48 rounded-full blur-3xl bg-foreground/30 opacity-0 left-[50%] top-[50%] -translate-x-48 translate-y-16"
        style={{
          animationDelay: "8000ms",
        }}
      ></div>
      <div
        className="home-ball absolute h-64 w-64 rounded-full blur-3xl bg-foreground/30 opacity-0 left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]"
        style={{
          animationDelay: "12000ms",
        }}
      ></div>
      <div className="flex-1 flex flex-col items-center gap-8 px-6">
        {page === "PROMPT" && (
          <div className="flex flex-col items-center gap-6">
            <img src="/xtend-light-1024.png" className="h-24 drop-shadow-lg" />
            <div className="flex flex-col items-center gap-2">
              <h1 className="text-4xl font-black bg-gradient-to-b from-white via-white to-gray-400 inline-block text-transparent bg-clip-text shadow-lg">
                xTend
              </h1>
              <p className="text-muted-foreground font-bold w-full max-w-xs text-center">
                Effortlessly turn your long-form content into engaging threads
                for X.
              </p>
            </div>
          </div>
        )}
        <div
          className={`w-full max-w-lg bg-background/80 flex flex-col ${
            page === "PROMPT" ? "" : "items-start p-2"
          } gap-2 border border-border rounded-lg shadow-lg`}
          ref={containerRef}
        >
          {page === "PROMPT" && (
            <PromptBox
              viewSettingsPage={() => setPage("SETTINGS")}
              setIsOutputPageVisible={setIsOutputPageVisible}
            />
          )}
          {page === "SETTINGS" && (
            <Settings viewPromptPage={() => setPage("PROMPT")} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PromptPage;
