import { createContext, useContext, useState } from "react";

export type ThreadGenerationConfig = {
  characterLimit: number;
  characterLimitUsage: number | undefined;
  postCount: number | undefined;
  writingStyle: "UNCHANGED" | "FUN" | "FORMAL";
  generateTags: "NONE" | "FIRST_POST" | "ALL_POSTS";
  useEmojis: boolean;
};

type SettingsType = {
  characterLimit: number;
  characterLimitUsage: number;
  isCharacterLimitAuto: boolean;
  postCount: number;
  isPostCountAuto: boolean;
  writingStyle: "UNCHANGED" | "FUN" | "FORMAL";
  generateTags: "NONE" | "FIRST_POST" | "ALL_POSTS";
  useEmojis: boolean;
};

type SettingsContextType = {
  settings: SettingsType;
  setSettings: (settings: SettingsType) => void;
  generateThreadGenerationConfig: () => ThreadGenerationConfig;
};

export const DEFAULT_SETTINGS = {
  characterLimit: 250,
  characterLimitUsage: 50,
  isCharacterLimitAuto: true,
  postCount: 5,
  isPostCountAuto: true,
  writingStyle: "UNCHANGED",
  generateTags: "NONE",
  useEmojis: true,
} satisfies SettingsType;

const SettingsContext = createContext<SettingsContextType | null>(null);

export const SettingsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [settings, setSettings] = useState<SettingsType>(DEFAULT_SETTINGS);

  const generateThreadGenerationConfig = (): ThreadGenerationConfig => {
    return {
      characterLimit: parseInt(settings.characterLimit.toFixed(0)),
      characterLimitUsage: settings.isCharacterLimitAuto
        ? undefined
        : settings.characterLimitUsage,
      postCount: settings.isPostCountAuto
        ? undefined
        : parseInt(settings.postCount.toFixed(0)),
      writingStyle: settings.writingStyle,
      generateTags: settings.generateTags,
      useEmojis: settings.useEmojis,
    };
  };

  return (
    <SettingsContext.Provider
      value={{ settings, setSettings, generateThreadGenerationConfig }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  const settings = useContext(SettingsContext);
  if (!settings) {
    throw new Error("useSettings must be wrapped in <SettingsProvider>");
  }
  return settings;
};
