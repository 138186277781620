import React, { useEffect, useRef, useState } from "react";
import { Button } from "../ui/button";
import { ChevronLeft, CircleAlert } from "lucide-react";
import Post from "./Post";
import autoAnimate from "@formkit/auto-animate";
import useGeneratedThread, {
  Post as PostType,
} from "@/contexts/generated-thread";
import PostSkeleton from "./PostSkeleton";
import { useSettings } from "@/contexts/settings";

const PLACEHOLDER_POST: PostType = {
  body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.",
};

const OutputPage = ({
  setIsOutputPageVisible,
  showLeftBorder = true,
}: {
  setIsOutputPageVisible: (visible: boolean) => void;
  showLeftBorder?: boolean;
}) => {
  const {
    settings: { postCount: expectedPostsLength },
  } = useSettings();
  const expectedPostsLengthInteger = parseInt(expectedPostsLength.toFixed(0));

  const [posts, setPosts] = useState<PostType[]>([]);
  const { data, error, isLoading } = useGeneratedThread();
  useEffect(() => {
    if (data?.id && data.posts) {
      setPosts(data.posts);
    }
  }, [data]);

  const postsContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const postsContainer = postsContainerRef.current;
    if (!postsContainer) return;

    autoAnimate(postsContainer);
  }, [postsContainerRef]);

  return (
    <div
      className={`flex-1 ${
        showLeftBorder ? "border-l border-l-border" : ""
      } h-full relative flex flex-col items-center overflow-y-auto`}
    >
      <div className="w-full flex items-center border-b border-b-border p-2 gap-2 sticky top-0 z-20 backdrop-blur-lg bg-background/70 shadow-lg">
        <Button
          size={"sm"}
          variant={"ghost"}
          className="h-10 w-10 p-0"
          onClick={() => setIsOutputPageVisible(false)}
        >
          <ChevronLeft size={22} />
        </Button>
        <strong className="text-lg">Preview</strong>
      </div>
      <div
        className="w-full max-w-lg p-4 gap-4 flex flex-col flex-1 relative"
        ref={postsContainerRef}
      >
        {isLoading ? (
          new Array(expectedPostsLengthInteger ?? 2).fill(0).map((_, index) => {
            return (
              <PostSkeleton
                key={index}
                appearAfter={index * 1000}
                showConnector={
                  index === expectedPostsLengthInteger - 1 ? false : true
                }
              />
            );
          })
        ) : error ? (
          <div className="text-center text-muted-foreground flex flex-col items-center justify-center gap-2 flex-1">
            <CircleAlert size={64} className="text-muted-foreground/80" />
            <span>{error === null ? "Something went wrong." : "LOL"}</span>
          </div>
        ) : (
          posts.map((post, index) => {
            return (
              <Post
                key={index}
                body={post.body}
                showConnector={index === posts.length - 1 ? false : true}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default OutputPage;
