import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const generateHexString = (): string => {
  return Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, "0");
};

export const safeParse = <T extends any>(value: string, fallback?: T): any => {
  try {
    return JSON.parse(value);
  } catch {
    return fallback;
  }
};

export const isObject = (value: any): value is object => {
  return (
    typeof value === "object" &&
    value !== null &&
    !Array.isArray(value) &&
    !(value instanceof RegExp) &&
    !(value instanceof Date) &&
    !(value instanceof Set) &&
    !(value instanceof Map)
  );
};

type ParsedTweetPart = {
  type: "text" | "tag";
  value: string;
};

export function parseTweetString(input: string): Array<ParsedTweetPart> {
  // Define the regex pattern to match valid hashtags
  const pattern = /(?<![a-zA-Z0-9])#([a-zA-Z0-9]*)(?=[^a-zA-Z0-9]|$)|([^#]+)/g;

  const result: Array<ParsedTweetPart> = [];
  let match: RegExpExecArray | null;

  while ((match = pattern.exec(input)) !== null) {
    // If there's a valid tag
    if (match[1]) {
      result.push({ type: "tag", value: match[0] });
    }
    // If there's regular text
    else if (match[2]) {
      result.push({ type: "text", value: match[2] });
    }
  }

  return result;
}
