import { usePrompts } from "@/contexts/prompts";
import { useSettings } from "@/contexts/settings";
import { isObject, safeParse } from "@/lib/utils";
import generateThread from "@/services/generateThread";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import React, { createContext, useContext, useState } from "react";

export type Post = {
  body: string;
  hyperlinks?: {
    [key: string]: string;
  };
};

export type GeneratedThread = {
  id: string;
  posts?: Post[] | null;
};

type GeneratedThreadContextType = {
  isLoading: boolean;
  error: Error | null;
  data: GeneratedThread | null;
  fetch: () => void;
};

const useGeneratedThreadQuery = () => {
  const { postContent, userPrompt, isUserPromptApplicable } = usePrompts();
  const { generateThreadGenerationConfig } = useSettings();

  const config = generateThreadGenerationConfig();

  const query = useQuery({
    queryKey: ["generated-thread"],
    queryFn: async () => {
      const response = await generateThread(
        postContent,
        isUserPromptApplicable ? userPrompt : "",
        config
      );
      console.log(response);
      return response;
    },
    enabled: false,
  });

  return query;
};

const GeneratedThreadContext = createContext<GeneratedThreadContextType | null>(
  null
);

// Function that uses regex to extract the outermost substring that follows the pattern "{...}"
const extractObjectFromResponse = (response: string) => {
  const regex = /\{.*\}/s;
  const match = response.match(regex);
  return match ? match[0] : null;
};

export const GeneratedThreadProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    isFetching: isLoading,
    refetch: fetch,
    isError,
    data: stringResponse,
    error,
  } = useGeneratedThreadQuery();

  let resolvedError = error;
  let generatedThread: GeneratedThread | null = null;

  if (!isLoading && !isError) {
    const generatedThreadJSON = stringResponse
      ? extractObjectFromResponse(stringResponse)
      : null;
    if (!generatedThreadJSON) {
      resolvedError = new Error(
        "The request could not be completed successfully."
      );
    } else {
      const parsedGenereratedThread = generatedThreadJSON
        ? safeParse(generatedThreadJSON)
        : undefined;
      if (!parsedGenereratedThread) {
        resolvedError = new Error("The request could not be fulfilled.");
      } else {
        if (isObject(parsedGenereratedThread)) {
          if ("id" in parsedGenereratedThread) {
            let posts: Post[] | null = null;
            if ("posts" in parsedGenereratedThread) {
              posts = parsedGenereratedThread.posts as Post[] | null;
            }
            generatedThread = {
              id: parsedGenereratedThread.id as string,
              posts,
            };
            if (!posts) {
              resolvedError = new Error("This request is invalid.");
            }
          } else {
            resolvedError = new Error("The request could not be fulfilled.");
          }
        } else {
          resolvedError = new Error("The request could not be fulfilled.");
        }
      }
    }
  }

  const query = {
    isLoading,
    error,
    data: generatedThread,
    fetch,
  };

  return (
    <GeneratedThreadContext.Provider value={query}>
      {children}
    </GeneratedThreadContext.Provider>
  );
};

const useGeneratedThread = () => {
  const generatedThread = useContext(GeneratedThreadContext);
  if (!generatedThread) {
    throw new Error(
      "useGeneratedThread must be wrapped in <GeneratedThreadProvider>"
    );
  }
  return generatedThread;
};

export default useGeneratedThread;
